import { useMemo, useState, useEffect } from "react";
import TableBiblioteca from "../Components/TableBiblioteca";
import Api from '../Auth/Api';
import { Controller, useForm } from "react-hook-form";
import { Toast, Modal } from 'react-bootstrap';
import { decodeJWT, formatDateTime } from "../Components/jwtUtils";

function Biblioteca() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nome_completo = localStorage.getItem("nome_completo")
    const nivel = decodeJWT(token).nivel;
    const [showModalAddArquivo, setShowModalAddArquivo] = useState(false);
    const [uploadCursoProgress, setUploadCursoProgress] = useState(0);
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [cursoAddFile, setCursoAddFile] = useState(false);
    const [tipoAdicao, setTipoAdicao] = useState('arquivo');
    const [showConfirmationModal, setShowConfirmationModal] = useState({ show: false });


    const handleDeleteItem = async () => {
        setShowConfirmationModal({ show: false });
        async function fetchData() {
            try {
                const response = await Api.delete(`api/deletar-item-biblioteca/${showConfirmationModal.id}`, { headers: { Authorization: `Bearer ${token}` } });
                setToast({ header: 'Sucesso!', body: response.data.message })
                setShowToast(true)
            } catch (error) {
                setToast({ header: 'Erro!', body: error.response.data.message })
                console.error(error.response.data.message)
            }
        }
        fetchData();
        setShowToast(true);
    }

    const [dataBiblioteca, setDataBiblioteca] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/biblioteca`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataBiblioteca(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, toast]);

    const {
        register: register_C,
        handleSubmit: handleSubmit_C,
        control: control_C,
        reset: reset_C,
        setValue: setValue_C,
        formState: { errors: errors_C },
    } = useForm();

    const generateUrl = useMemo(() => {
        return ({ url, arquivo }) => {
            let novaUrl;

            if (url) {
                novaUrl = url;
            } else {
                novaUrl = `${process.env.REACT_APP_FILES_URL}/${arquivo}&token=${token}&type=download`;
            }
            return novaUrl;
        };
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'icone',
                Cell: () => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-file-text" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome'
            },
            {
                Header: () => null,
                accessor: 'nomeUsuario',
                Cell: ({ value }) => (
                    <div className="text-left">
                        {value}
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'data',
                Cell: ({ value }) => (
                    <div className="text-left">
                        {formatDateTime(value)}
                    </div>
                )
            },
            {
                Header: () => null,
                id: 'actions',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div>
                        <button className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2" onClick={() => { window.open(generateUrl({ url: original.url, arquivo: original.arquivo }), '_blank') }}>
                            <i className="bi bi-cloud-arrow-down" />
                        </button>
                        {nivel !== 'Aluno' &&
                            <button className={`btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2 ${original.nomeUsuario !== nome_completo ? 'disabled' : ''}`} onClick={() => { setShowConfirmationModal({ show: true, id: original.id }) }}>
                                <i className="bi bi-trash" />
                            </button>
                        }
                    </div>
                )
            }
        ],
        [generateUrl, nivel, nome_completo]
    )

    const onSubmitAddArquivo = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        try {
            const response = await Api.post("/api/novo-item-biblioteca", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadCursoProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadCursoProgress('Aguarde... finalizando...')
                        }, 1000);
                    }
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_C()
            setValue_C("arquivo", "")
            setCursoAddFile(false)
            setUploadCursoProgress(0)
            setShowModalAddArquivo(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo curso:", error);
        }
        setShowToast(true);
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <Modal show={showConfirmationModal.show} onHide={() => setShowConfirmationModal({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Você tem certeza que deseja enviar suas respostas?
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button onClick={handleDeleteItem} className="btn btn-primary text-white px-5 py-2">
                        Deletar
                    </button>
                    <button className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowConfirmationModal({ show: false })} >
                        Cancelar
                    </button>
                </Modal.Footer>
            </Modal>


            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>

                {nivel !== 'Aluno' &&
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <span className="text-primary fs-1">Biblioteca</span>
                        <button onClick={() => { setShowModalAddArquivo(true); setUploadCursoProgress(0); reset_C() }} className="d-flex align-items-center justify-content-center btn btn-primary text-white fw-semibold"><i className="bi bi-plus fs-4"></i> <span className="d-none d-md-block">| Adicionar arquivo</span></button>
                    </div>
                }

                <p className="fs-6">Confira nossa bibilioteca</p>

                <TableBiblioteca columns={columns} data={dataBiblioteca} titulo="Biblioteca" />

            </div>

            {/* Deletar prova */}
            <Modal size="lg" show={showModalAddArquivo} onHide={() => setShowModalAddArquivo(false)}>
                <form onSubmit={handleSubmit_C(onSubmitAddArquivo)}>
                    <Modal.Header closeButton>
                        <div className='row'>
                            <span className="fw-medium fs-4 text-primary">Adicione um novo arquivo</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 mb-5 mb-md-3">
                                                        <label className="form-label text-primary fw-medium">Nome do arquivo</label>
                                                        <input type="text" {...register_C('nome', { required: true })} className="form-control" placeholder="Insira um nome para seu arquivo" />
                                                        {errors_C.nome && <span className='text-primary fs-7'>Nome é obrigatório.</span>}
                                                    </div>

                                                    {/* Seleção entre URL e Arquivo */}
                                                    <div className="col-12 col-md-4 mb-5 mb-md-3">
                                                        <label className="form-label text-primary fw-medium">Tipo de adição</label>
                                                        <div>
                                                            <input
                                                                className="form-check-input ms-3"
                                                                type="radio"
                                                                name="tipoAdicao"
                                                                value="arquivo"
                                                                checked={tipoAdicao === 'arquivo'}
                                                                onChange={() => setTipoAdicao('arquivo')}
                                                            /> Arquivo
                                                            <input
                                                                className="form-check-input ms-3"
                                                                type="radio"
                                                                name="tipoAdicao"
                                                                value="url"
                                                                checked={tipoAdicao === 'url'}
                                                                onChange={() => setTipoAdicao('url')}
                                                            /> URL
                                                        </div>
                                                    </div>

                                                    {tipoAdicao === 'url' ? (
                                                        <div className="col-12 col-md-12 mb-5 mb-md-3">
                                                            <label className="form-label text-primary fw-medium">URL</label>
                                                            <input type="text" {...register_C('url')} className="form-control" placeholder="Insira a URL aqui" />
                                                        </div>
                                                    ) : (
                                                        <div className="col-12 col-md-12 mb-5 mb-md-3">
                                                            <label className="form-label text-primary fw-medium">Selecione um arquivo</label>
                                                            <label htmlFor="arquivo" className={`form-control label-input ${cursoAddFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i> Enviar Arquivo</label>
                                                            <Controller
                                                                control={control_C}
                                                                name="arquivo"
                                                                rules={{ required: true }}
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <input
                                                                        id='arquivo'
                                                                        type="file"
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        onChange={(e) => {
                                                                            const files = e.target.files;
                                                                            onChange(files);
                                                                            if (files && files.length > 0) {
                                                                                setCursoAddFile(true);
                                                                            } else {
                                                                                setCursoAddFile(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors_C.arquivo && <span className='text-primary fs-7'>Arquivo é obrigatório.</span>}
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="progress col-12">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${uploadCursoProgress}` }} aria-valuenow={uploadCursoProgress} aria-valuemin="0" aria-valuemax="100">{uploadCursoProgress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <div className="d-flex">
                            <button type="submit" onClick={() => { }} className="btn btn-primary text-white px-3 py-2">Confirmar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => { setShowModalAddArquivo(false); setUploadCursoProgress(0); setTipoAdicao('arquivo') }}>Cancelar</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal >
        </>
    )
}

export default Biblioteca;
