import { Link } from "react-router-dom";
import { decodeJWT } from "../Components/jwtUtils";
import { useEffect, useState } from "react";
import Api from "../Auth/Api";

function Sidebar() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const [mensagensNaoLidas, setMensagensNaoLidas] = useState([]);

    // Buscar na API mensagens não lidas
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/mensagens-nao-lidas`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMensagensNaoLidas(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token])

    return (
        <>
            <div className="sidebar-content">
                <div className="d-flex flex-md-column align-items-center bg-light px-2 px-md-4 py-3 py-md-5 sidenav">
                    <Link to={`${nivel === 'Administrador' || nivel === 'Tutor' ? '/dashboard' : '/aluno/dashboard'}`}>
                        <div className="fs-3 py-0 py-md-2 text-center" title="Home">
                            <i className="bi cursor-pointer p-12px rounded bi-house-door d-flex bg-primary text-light"></i>
                            <span className="d-md-block text-center fs-8 d-none">Home</span>
                        </div>
                    </Link>
                    <Link to="/meus-cursos">
                        <div className="fs-3 py-0 py-md-2 text-center" title="Cursos">
                            <i className="bi cursor-pointer p-12px rounded bi-book"></i>
                            <span className="d-md-block text-center fs-8 d-none">Cursos</span>
                        </div>
                    </Link>

                    <Link to="/chat">
                        <div className="fs-3 py-0 py-md-2 text-center" title="Chat">
                            <div className="position-relative">
                                <i className="bi cursor-pointer p-12px rounded chat bi-chat-left"></i>
                                <span style={{ display: mensagensNaoLidas.mensagens_nao_lidas > 0 ? "block" : "none", marginLeft: '-10px' }} className="badge bg-danger position-absolute top-0 start-100 translate-middle badge-pill fs-7">{mensagensNaoLidas.mensagens_nao_lidas}</span>
                            </div>
                            <span className="d-md-block text-center fs-8 d-none">Mensagens</span>
                        </div>
                    </Link>


                    <Link to={`${nivel === 'Administrador' || nivel === 'Tutor' ? '/certificados' : '/aluno/certificado'}`}>
                        <div className="fs-3 py-0 py-md-2 text-center" title="Certificados">
                            <i className="bi cursor-pointer p-12px rounded bi-patch-check"></i>
                            <span className="d-md-block text-center fs-8 d-none">Certificado</span>
                        </div>
                    </Link>


                    <Link to="/biblioteca">
                        <div className="fs-3 py-0 py-md-2 text-center" title="Biblioteca">
                            <i className="bi bi-hdd p-12px rounded bi-dash-square"></i>
                            <span className="d-md-block text-center fs-8 d-none">Biblioteca</span>
                        </div>
                    </Link>

                    {nivel === 'Aluno' && (
                        <Link to="/calendario">
                            <div className="fs-3 py-0 py-md-2 text-center" title="Calendário">
                                <i className="bi cursor-pointer p-12px rounded bi-calendar"></i>
                                <span className="d-md-block text-center fs-8 d-none">Calendário</span>
                            </div>
                        </Link>
                    )}

                </div>
            </div>
        </>
    )
}

export default Sidebar;
