import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { formatDateSimple } from '../Components/jwtUtils';

Font.register({
    family: 'Bold',
    src: '../../assets/fonts/bold.ttf',
});
Font.register({
    family: 'ExtraLight',
    src: '../../assets/fonts/extralight.ttf',
});
Font.register({
    family: 'Regular',
    src: '../../assets/fonts/regular.ttf',
});

const styles = StyleSheet.create({
    body: {
        padding: 20,
    },
    title: {
        fontSize: 36,
        textAlign: 'center',
        marginBottom: 10,
        fontFamily: 'Bold',
    },
    studentInfo: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    text: {
        fontSize: 12,
        fontWeight: 'normal',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        fontSize: 10,
        padding: 5,
    },
    courseTitle: {
        fontSize: 14,
        marginVertical: 5,
        fontFamily: 'Regular',
    }
});

const GerarHistoricoEscolar = ({ data }) => {

    const Table = ({ children }) => (
        <View style={styles.table}>{children}</View>
    );

    const Row = ({ children }) => (
        <View style={styles.tableRow}>{children}</View>
    );

    const Cell = ({ children, style }) => (
        <View style={[styles.tableCol, style]}>
            <Text style={styles.tableCell}>{children}</Text>
        </View>
    );


    return (
        <Document>
            <Page size="A4">
                <View style={styles.body}>
                    <Text style={styles.title}>Histórico Escolar</Text>
                    <View style={styles.studentInfo}>
                        <View style={styles.studentInfo}>
                            <Text style={{ fontFamily: 'Bold', fontSize: 12 }}>Nome: <Text style={{ fontFamily: 'Regular' }}>{data.info_usuario && data.info_usuario.nome_completo}</Text></Text>
                            <Text style={{ fontFamily: 'Bold', fontSize: 12 }}>E-mail: <Text style={{ fontFamily: 'Regular' }}>{data.info_usuario && data.info_usuario.email}</Text></Text>
                        </View>
                    </View>
                    {data.topico && data.topico.map(topic => (
                        <View key={topic.idTopico}>
                            <Text style={{ fontFamily: 'Bold' }}>Curso: <Text style={{ fontFamily: 'Bold' }}>{topic.nomeTopico}</Text></Text>
                            {topic.cursos && topic.cursos.map(course => (
                                <View key={course.idCurso} style={{ marginBottom: 10 }}>
                                    <Text style={styles.courseTitle}>{course.nomeCurso}</Text>
                                    <Table>
                                        <Row>
                                            <Cell style={{ width: '40%', fontFamily: 'Bold' }}>Matéria</Cell>
                                            <Cell style={{ width: '15%', textAlign: 'center', fontFamily: 'Bold' }}>Carga Horária</Cell>
                                            <Cell style={{ width: '15%', textAlign: 'center', fontFamily: 'Bold' }}>Nota Final</Cell>
                                            <Cell style={{ width: '15%', fontFamily: 'Bold' }}>Período</Cell>
                                            <Cell style={{ width: '15%', textAlign: 'center', fontFamily: 'Bold' }}>Situação Final</Cell>
                                        </Row>
                                        {course.modulos && course.modulos.map(modulo => (
                                            <View key={modulo.idModulo}>
                                                <Row>
                                                    <Cell style={{ width: '40%' }}>{modulo.nomeModulo}</Cell>
                                                    <Cell style={{ width: '15%', textAlign: 'center' }}>{modulo.cargaHoraria.split(':')[0] || '-'}</Cell>
                                                    <Cell style={{ width: '15%', textAlign: 'center' }}>{modulo.nota !== null ? modulo.nota : "CUR"}</Cell>
                                                    <Cell style={{ width: '15%' }}>{formatDateSimple(course.dataInicio)} até <br />{formatDateSimple(course.dataFinal)}</Cell>
                                                    <Cell style={{ width: '15%', textAlign: 'center' }}>{modulo.situacao}</Cell>
                                                </Row>

                                                {modulo.avaliacoes_extras && modulo.avaliacoes_extras.length > 0 && (
                                                    <>
                                                        {modulo.avaliacoes_extras.map(avaliacao => (
                                                            <Row key={avaliacao.nomeAvaliacaoExtra}>
                                                                <Cell style={{ width: '40%' }}>{avaliacao.nomeAvaliacaoExtra}</Cell>
                                                                <Cell style={{ width: '15%', textAlign: 'center' }}>-</Cell>
                                                                <Cell style={{ width: '15%', textAlign: 'center' }}>{avaliacao.nota !== null ? avaliacao.nota : "N/A"}</Cell>
                                                                <Cell style={{ width: '15%' }}>{formatDateSimple(avaliacao.data_disponibilidade)} até <br />{formatDateSimple(avaliacao.data_disponibilidade_fim)}</Cell>
                                                                <Cell style={{ width: '15%', textAlign: 'center' }}>{avaliacao.situacao}</Cell>
                                                            </Row>
                                                        ))}
                                                    </>
                                                )}
                                            </View>
                                        ))}
                                    </Table>
                                </View>
                            ))}
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default GerarHistoricoEscolar;
