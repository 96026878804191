import React, { useState } from 'react';
import useAuth from '../Auth/hook_useAuth';
import Api from '../Auth/Api';
import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Login() {

    const navigate = useNavigate();

    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [codigo, setCodigo] = useState("");
    const [password, setPassword] = useState("");
    const { signin } = useAuth();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await Api.post("/login", { codigo, password });
            signin({ token: response.data.token, nome_completo: response.data.nome_completo, nivel: response.data.nivel, foto: response.data.foto, primeiro_login: response.primeiro_login }, true);
            
            if (response.data.primeiro_login === 0 || response.data.primeiro_login === null) {
                navigate("/primeiro-login");
            } else {
                if (response.data.nivel === 'Aluno') {
                    navigate("/aluno/dashboard");
                } else {
                    navigate("/dashboard");
                }
            }
            localStorage.setItem("primeiro_login", response.data.primeiro_login);
            
        } catch (error) {
            setToast({ header: 'Ocorreu um erro!', body: 'Seu código ou senha senha estão incorretos.' })
            setShowToast(true);
        }

    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} style={{ position: 'fixed', left: '50%', bottom: '10px', transform: 'translateX(-50%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <nav className="navbar bg-primary">
                <div className="container-fluid">
                    <span className="d-flex justify-content-center w-100 navbar-brand m-5px">
                        <img src="./../assets/logo-white.png" className="cursor-pointer" alt="" />
                    </span>
                </div>
            </nav>
            <div className="fade-in w-100">
                <div className="container-fluid text-center login-container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-12 col-xl-6 mx-md-5">
                            <form className="d-flex flex-column justify-content-center px-4" onSubmit={handleSubmit}>
                                <div className="d-flex justify-content-center mb-4">
                                    <img src="./../assets/logo.png" className="logo d-block" alt="" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" placeholder="Código" className="form-control" value={codigo} onChange={e => setCodigo(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <input type="password" placeholder="Senha" className="form-control" value={password} onChange={e => setPassword(e.target.value)} required />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" />
                                        <label className="form-check-label"> Lembrar minha senha </label>
                                    </div>
                                    <button type="button" className="btn btn-link link" onClick={() => { navigate("/recuperar") }}>Esqueci minha senha</button>
                                </div>
                                <div className="d-flex flex-column gap-4">
                                    <button type="submit" className="btn btn-primary text-light px-4 py-1 py-md-3">Entrar</button>
                                </div>
                            </form>
                        </div>
                        <div className="d-none d-md-block col p-0 spacing-img"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;