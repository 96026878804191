import { useMemo, useState, useEffect } from "react";
import TableUsuarios from '../Components/TableUsuarios';
import Api from '../Auth/Api';
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { Modal, Toast } from 'react-bootstrap';
import { decodeJWT } from "../Components/jwtUtils";

function ListaUsuarios() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;

    const [selectedFilter, setSelectedFilter] = useState("Aluno");
    const [showModalEditarUsuario, setShowModalEditarUsuario] = useState({ show: false });
    const [showModalResetSenha, setShowModalResetSenha] = useState({ show: false });
    const [showModalDeletar, setShowModalDeletar] = useState({ show: false });
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [selectCursos, setSelectCursos] = useState(false);

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const [dataUsuarios, setDataUsuarios] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios/nivel/${selectedFilter}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUsuarios(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, selectedFilter, toast]);

    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, toast]);

    const [dataUnidades, setDataUnidades] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-unidades`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUnidades(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const dataNiveis = useMemo(() => [
        { value: 'Aluno', label: 'Aluno' },
        { value: 'Tutor', label: 'Tutor' },
        { value: 'Administrador', label: 'Administrador' },
    ], []);

    // criar funcao de submit
    const onSubmitEditarUsuario = async (data) => {

        data.cursos = (data.cursos || []).map((curso) => curso.value);
        try {
            const response = await Api.post(`api/editar-usuario/${data.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar nova aula:", error)
        }
        setShowToast(true)
        setShowModalEditarUsuario({ show: false })
    }

    const handleResetPassword = async (id) => {
        try {
            const response = await Api.post(`api/resetar-senha/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao resetar senha:", error)
        }
        setShowModalResetSenha({ show: false })
        setShowToast(true)
    };

    const handleDeleteUser = async (id) => {
        try {
            const response = await Api.delete(`api/deletar-usuario/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao deletar usuário:", error)
        }
        setShowModalDeletar({ show: false })
        setShowToast(true)
    };

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                accessor: row => row.foto,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            {value ? <img className="object-fit-cover" src={`${process.env.REACT_APP_API_URL}/fotos/${value}`} alt="Profile" style={{ height: '42px', width: '42px' }} /> : <i className="bi bi-person" />}
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome_completo'
            },
            {
                Header: () => null,
                accessor: 'codigo'
            },
            {
                Header: () => null,
                accessor: 'nivel'
            },
            {
                Header: () => null,
                accessor: 'email'
            },
            {
                Header: () => null,
                accessor: 'unidade'
            },
            {
                Header: () => null,
                id: 'actions',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        <button onClick={() => {
                            setShowModalEditarUsuario({ show: true, content: original });
                            setValue('nome_completo', original.nome_completo);
                            setValue('email', original.email);
                            setValue('id', original.id);
                            setValue('codigo', original.codigo);

                            const selectNivel = dataNiveis.find(item => item.label === original.nivel);
                            setValue('nivel', { value: selectNivel.label, label: selectNivel.label });

                            const selectUnidade = dataUnidades.find(item => item.nome === original.unidade);
                            if (selectUnidade && selectUnidade.nome) {
                                setValue('unidade', { value: selectUnidade.nome, label: selectUnidade.nome });
                            } else {
                                setValue('unidade', { value: '', label: '' });
                            }

                            let selectedCursos = [];

                            if (original.permissao_cursos && original.permissao_cursos.trim() !== "") {
                                try {
                                    const cursosArray = JSON.parse(original.permissao_cursos);
                                    selectedCursos = cursosArray.map(cursoId => {
                                        return dataCursos.find(curso => curso.id === cursoId);
                                    }).filter(Boolean);
                                } catch (error) {
                                    console.error("Erro ao analisar permissao_cursos:", error);
                                }
                            }
                            const cursosForSelect = selectedCursos.map(curso => ({
                                value: curso.id,
                                label: curso.nome
                            }));
                            setValue('cursos', cursosForSelect);
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                            <i className="bi bi-pencil" />
                        </button>
                        <button onClick={() => { setShowModalResetSenha({ show: true, id: original.id, nome: original.nome_completo }) }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                            <i className="bi-key-fill" />
                        </button>
                    </>
                )
            }
        ],
        [setValue, dataUnidades, dataNiveis, dataCursos]
    )

    const filterSelect = useMemo(
        () => [
            { value: 'Aluno', label: 'Alunos' },
            { value: 'Tutor', label: 'Tutores' },
            { value: 'Administrador', label: 'Administradores' }
        ].filter(option => option.value === 'Aluno' || nivel !== 'Tutor'),
        [nivel]
    );

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#1546db",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };
    
    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Lista de Usuários</span>
                <p className="fs-6">Consulte os usuários cadatrados, filtre, edite e exclua.</p>
                <TableUsuarios columns={columns} data={dataUsuarios} setSelectedFilter={setSelectedFilter} filterSelect={filterSelect} titulo="Lista de Usuários" />
            </div>

            <Modal size="lg" show={showModalEditarUsuario.show} onHide={() => setShowModalEditarUsuario({ show: false })}>
                <form onSubmit={handleSubmit(onSubmitEditarUsuario)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar usuário</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex">
                                            <div className="d-flex flex-column w-100">

                                                <div className="row mb-3">

                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome</label>
                                                            <input type="text" {...register("nome_completo", { required: true })} placeholder="Nome do usuário" className="form-control border-primary" />
                                                            {errors.nome_completo && <span className='text-primary fs-7'>Nome completo é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-5">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Matrícula</label>
                                                            <input type="text" {...register("codigo", { required: true })} readOnly className="form-control border-primary" />
                                                            {errors.codigo && <span className='text-primary fs-7'>Código é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-7">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Email</label>
                                                            <input type="email" {...register("email", { required: true })} placeholder="josedasilva@email.com" className="form-control border-primary" />
                                                            {errors.email && <span className='text-primary fs-7'>Email é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Unidade</label>
                                                            <Controller
                                                                name="unidade"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataUnidades.map((value) => {
                                                                            return {
                                                                                value: value.nome,
                                                                                label: `${value.nome}`,
                                                                            };
                                                                        })}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        onChange={value => field.onChange(value)}
                                                                        placeholder="Selecione uma unidade"
                                                                        noOptionsMessage={() => "Nenhuma unidade encontrada."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.unidade && <span className='text-primary fs-7'>Selecione uma unidade.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Tipo Usuário</label>
                                                            <Controller
                                                                name="nivel"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataNiveis}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        onChange={value => {
                                                                            if (value.label === 'Aluno') {
                                                                                setSelectCursos(true);
                                                                            } else {
                                                                                setSelectCursos(false);
                                                                            }
                                                                            field.onChange(value);
                                                                        }}
                                                                        placeholder="Selecione tipo do usuário"
                                                                    />
                                                                )}
                                                            />
                                                            {errors.nivel && <span className='text-primary fs-7'>Tipo de usuário é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    {(selectCursos || selectedFilter === 'Aluno') &&
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Cursos</label>
                                                                <Controller
                                                                    name="cursos"
                                                                    control={control}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            isMulti
                                                                            options={dataCursos.map((value) => {
                                                                                return {
                                                                                    value: value.id,
                                                                                    label: `${value.nome}`,
                                                                                };
                                                                            })}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            onChange={value => field.onChange(value)}
                                                                            noOptionsMessage={() => "Nenhum curso encontrado."}
                                                                            placeholder="Selecione os cursos"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.cursos && <span className='text-primary fs-7'>Selecione pelo menos um curso.</span>}
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light gap-1">
                        <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalEditarUsuario({ show: false })}>Cancelar</button>
                        <button type="button" className="btn btn-danger text-white px-3 py-2" onClick={() => { setShowModalDeletar({ show: true, id: showModalEditarUsuario.content.id, nome_completo:showModalEditarUsuario.content.nome_completo }); setShowModalEditarUsuario({ show: false }) }}><i className="bi bi-trash3"></i></button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size='lg' show={showModalResetSenha.show} onHide={() => setShowModalResetSenha({ show: false })}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Resetar senha</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja resetar a senha de <b>{showModalResetSenha.nome}</b>?</p>
                                                    <p>Essa ação ira resetar a senha do usuário para o padrão. Essa ação não podera ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleResetPassword(showModalResetSenha.id)}>Resetar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalResetSenha({ show: false })}>Cancelar</button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showModalDeletar.show} onHide={() => setShowModalDeletar({ show: false })}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar usuário</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar o usuário <b>{showModalDeletar.nome_completo}</b>?</p>
                                                    <p>Essa ação ira deletar o usuário permanentemente. Essa ação não podera ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleDeleteUser(showModalDeletar.id)}>Deletar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalDeletar({ show: false })}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ListaUsuarios;