import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../Auth/hook_useAuth';
import { useEffect, useState } from 'react';
import Api from '../Auth/Api';
import { decodeJWT, obterIniciais } from '../Components/jwtUtils';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';

function Header() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const nivel = decodeJWT(token).nivel;
    const navigate = useNavigate();
    const { signout } = useAuth();
    const nome_completo = localStorage.getItem("nome_completo")
    const foto = localStorage.getItem("foto")
    const [showModalRelatorios, setShowModalRelatorios] = useState(false);

    const [dataNotificacoes, setDataNotificacoes] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/notificacoes`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataNotificacoes(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])


    return (
        <>
            <nav className="navbar bg-primary w-100">
                <div className="container d-flex justify-content-between w-100 flex-nowrap">
                    <span className="navbar-brand m-1">
                        <img className="cursor-pointer" alt="" src="./../assets/logo-white.png" />
                    </span>
                    <div className="d-flex align-items-center">
                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-none text-light p-0 fs-3' id="dropdown-basic">
                                <i className="bi bi-bell cursor-pointer"></i>
                                {dataNotificacoes.quantidade_notificacoes > 0 &&
                                    <span style={{ marginLeft: -20, marginTop: 5 }} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-primary fs-8">
                                        {dataNotificacoes.quantidade_notificacoes}
                                    </span>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {dataNotificacoes.notificacoes && dataNotificacoes.notificacoes.length > 0 ?
                                    dataNotificacoes.notificacoes.map((notificacao) => (
                                        <Dropdown.Item key={notificacao.id} className="d-flex justify-content-between align-items-center">
                                            <Link to={`/aulas/${notificacao.id_modulo}`}>
                                                <div className="fw-bold">{notificacao.nome_avaliacao}</div>
                                                <small className="text-muted fs-7">por {notificacao.nome_tutor}</small>
                                            </Link>
                                            <small className="badge bg-dark text-white fs-8 mt-4 ms-2">não lida</small>
                                        </Dropdown.Item>

                                    )) :
                                    <Dropdown.Item className="d-flex justify-content-between align-items-center">
                                        <div className="fw-normal">Nenhuma nova notificação</div>
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-none d-flex align-items-center px-4 pe-0 text-light gap-2' id="dropdown-basic">
                                {/* <i className="bi fs-1 bi-person-circle cursor-pointer"></i> */}
                                {foto !== 'null' ? <img alt="foto_perfil" src={`${process.env.REACT_APP_API_URL}/fotos/${foto}`} className='rounded-circle object-fit-cover border-light border border-2' width={40} height={40} /> : <i className="bi fs-1 bi-person-circle cursor-pointer"></i>}
                                <span className="text-light">{obterIniciais(nome_completo)}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header className='text-center text-muted'>{nome_completo}</Dropdown.Header>
                                {nivel === 'Administrador' &&
                                    <Dropdown.Item href="/listar-topicos"><i className="bi bi-journals"></i> | Tema</Dropdown.Item>
                                }
                                {(nivel === 'Administrador' || nivel === 'Tutor') ?
                                    <Dropdown.Item href="/historico-escolar"><i className="bi bi-card-checklist"></i> | Histórico Escolar</Dropdown.Item>
                                    :
                                    <Dropdown.Item href="/aluno/historico-escolar"><i className="bi bi-card-checklist"></i> | Histórico Escolar</Dropdown.Item>
                                }
                                {(nivel === 'Administrador' || nivel === 'Tutor') &&
                                    <Dropdown.Item href="/listar-usuarios"><i className="bi bi-person"></i> | Lista de Usuários</Dropdown.Item>
                                }
                                {(nivel === 'Administrador' || nivel === 'Tutor') &&
                                    <Dropdown.Item onClick={() => setShowModalRelatorios(true)}><i className="bi bi-clipboard2-data"></i> | Relatórios</Dropdown.Item>
                                }
                                <Dropdown.Item href="/configuracoes"><i className="bi bi-gear"></i> | Configurações</Dropdown.Item>
                                <Dropdown.Item onClick={() => [signout(), navigate("/")]}><i className="bi bi-box-arrow-right"></i> | Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>
            </nav>

            <Modal size="lg" show={showModalRelatorios} onHide={() => setShowModalRelatorios(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Relatórios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "#fbfbfb" }}>
                    <div className="container mt-2">
                        <div className="text-center">
                            <h5>Selecione um Relatório</h5>
                            <p className="text-muted">Escolha o tipo de relatório que você deseja visualizar.</p>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-4 text-center">
                                <Link to="/relatorios/grupo" onClick={() => setShowModalRelatorios(false)}>
                                    <div className="card border-0 shadow-sm rounded-5 report-card">
                                        <div className="card-body">
                                            <div className="rounded-circle bg-success text-white d-flex justify-content-center align-items-center mb-2">
                                                <i className="bi bi-people-fill"></i>
                                            </div>
                                            <p className='m-0'>Aulas em Grupo</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary text-primary" onClick={() => setShowModalRelatorios(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    )

}

export default Header;
